import React from "react";
import PromoCounter from "../PromoCounter";
import "./BannerPromo.scss";
import FilledButton from "../FilledButton";
const BannerPromo = (props) => {
  
  const handlePlay=(e)=>{
    props.handleJoinGameButton()
  }

  return (
    <div className="banner-mainbox">
      <div className="banner-box">
        <div className="banner-text">
          £500 POT <span>FREE GAME</span>
        </div>
        <PromoCounter record={"2024-09-28T11:30:00+01:00"} />
      </div>
      <FilledButton
        name={"Play Now!"}
        btnClass="primary-background home-buttons-read-all promo-btn"
        type={1}
        functionCall={handlePlay}
      />
    </div>
  );
};

export default BannerPromo;
