export const USER_LOGIN = "/auth/sign_in";
export const USER_REGISTER = "/auth/";
export const FORGET_PASSWORD = "/auth/password";
export const RESET_PASSWORD = "/auth/password";
export const USER_DATA = "/me";
export const LEAGUES_DATA = "/leagues";
export const STANDINGS_DATA = "/standings";
export const FIXTURES_DATA = "/fixtures";
export const RESULTS_DATA = "/results";
export const INSIGHTS_HTH_DATA = "head-to-head";
export const CONTACT_US = "contact_us";
export const START_DATE_PRIVATE_GAME = "round-start-dates";
export const CREATE_PRIVATE_GAME = "/games/";
export const UPDATE_USER = "/user";
export const GET_PRIVATE_GAME_ID = "games/1/private_link";
export const GET_PUBLIC_GAMES = "public_games?page=";
export const JOIN_GAME = "/join";
export const GET_ONGOING_GAMES = "/user/ongoing-games";
export const GET_PAST_GAMES = "/past_games";
export const GET_GAME_ACTIVITIES = "/game_activities?";
export const GET_PRIVATE_GAME_BY_LINK = "/private_game?link=";
export const GET_PRIVATE_GAME = "/private_game";
export const GET_TEAMS_PROBABILITY = "/probability";
export const GET_Availability = (username) =>
  `/usernames/check_availability?username=${username}`;
export const GET_AvailabilityPromoCode = (promoCode) =>
  `check_code_validity?referal_code=${promoCode}`;
export const GET_ALL_SELECTIONS = "/all-selections";
export const USER_GAMBLING_DATA = "/get_settings";
export const UPDATE_SAFER_GAMBLING = "/safer_gambling";
export const REMAINING_TEAMS = "fixture";
export const DELETE_PRIVATE_GAME = "game/";
export const WALLET = "/wallet";
export const ADD_CARD = "/cards";
export const ALL_TRANSACTION = "/transactions?type=";
export const GET_EMAIL_AVAILABILITY = "/email_availability";
export const SEND_INVITE = "/send_invite";
export const BASKETBALL_STATS = "/game/stats";
export const GET_NOTIFICATIONS = "/notifications";
export const NOTIFICATIONS_SEEN = "/notifications/read";
export const REFERRAL_LINK = "/referal_code";
export const META_EVENTS = "/facebook";
export const CREATE_PAYMENT = "/banks/create_payment";
export const PAYMENT_AMOUNT_CHECKS = "/deposit_messages";
export const EXTERNAL_AMOUNT_WITHDRAW = "/banks/external_account";
export const AMOUNT_WITHDRAW = "/banks/withdraw";
export const DEPOSIT_CHARGE = "/banks/charge";
export const GET_AMOUNT_TO_BE_PAID = (gameId,entries) =>
  `/game/${gameId}/calc_pay?entries=${entries}`;
export const CHECK_CODE_APPLICABILITY = () =>
  "/check_code_applicability";
export const APPLY_PROMO_CODE = "/apply_referral_code"