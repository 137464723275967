import React, { Suspense, useEffect, useState } from "react";
import { Carousel } from "antd";
import VideoPlayer from "./VideoPlayer";
import GameStep from "./GameStep/GameStep";
import JoinGame from "./JoinGame/JoinGame";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../utils/localStorage";
import { setNavItem } from "../../../utils/localStorage";
import HomeStep2 from "../../../assets/images/step_2.png";
import HomeStep1 from "../../../assets/images/step_1.png";
import HomeStep3 from "../../../assets/images/step_3.png";
import { useScreenWidth } from "../../../utils/useScreenWidth";
import IntroVideo from "../../../assets/videos/intro-video.mp4";
import { useMyContext } from "../../../helpers/Context/MyContext";
import AppleStoreIcon from "../../../assets/images/app-store.svg";
import PlayStoreIcon from "../../../assets/images/play-store.svg";
import RestrictedAccess from "../../modals/RestrictedAccess/RestrictedAccess";
import DepositLimitModal from "../../modals/DepositLimitModal/DepositLimitModal";
import VerificationWarning from "../../modals/VerificationWarning/VerificationWarning";
import ExcludedWarningModal from "../../modals/ExcludedWarningModal/ExcludedWarningModal";
import {
  BannerPromo,
  FilledButton,
  HomeNews,
  SimpleFilledButton,
} from "../../commonComponents";
import { sendMetaEvents } from "../../../helpers/genericFunctions";
import {
  getPublicGamesForPublic,
  getPublicGamesForUser,
} from "../../../utils/service";
import axios from "axios";
import "./Home.scss";
import Loading from "../../commonComponents/Loading/Loading";

const Home = () => {
  const [gamesData, setGamesData] = useState();
  const navigate = useNavigate();
  const screenWidth = useScreenWidth();
  const [isDepositModalOpen, setDepositModalOpen] = useState(false);
  const [isVerificationWarningOpen, setIsVerificationWarningOpen] =
    useState(false);
  const { contextData, setContextData } = useMyContext();
  const [userCountry, setUserCountry] = useState();
  const [accessRestricted, setAccessRestricted] = useState();
  const userToken = getToken();
  const [isExcluded, setIsExcluded] = useState(false);
  
  const openRestrictedModal = () => {
    setAccessRestricted(true);
  };
  const closeRestrictedModal = () => {
    setAccessRestricted(false);
  };
  const openDepositModal = () => {
    setDepositModalOpen(true);
  };
  const closeDepositModal = () => {
    setDepositModalOpen(false);
    setContextData((prevState) => {
      const newState = { ...prevState };
      delete newState.signupData;
      return newState;
    });
  };
  const openVerificationModal = () => {
    setIsVerificationWarningOpen(true);
  };
  const closeVerificationModal = () => {
    setIsVerificationWarningOpen(false);
    setContextData((prevState) => {
      const newState = { ...prevState };
      delete newState.signupData;
      return newState;
    });
  };

  const openExcludedModal = () => {
    setIsExcluded(true);
  };
  const closeExcludedModal = () => {
    setContextData((prevState) => {
      const newState = { ...prevState };
      delete newState.signupData;
      delete newState.isExcluded;
      return newState;
    });
    setIsExcluded(false);
  };
  const handleGameForUser = async () => {
    const response = await getPublicGamesForUser();
    if (response?.games?.length > 0) {
      const sortedArray = response?.games?.sort((a, b) => b.pot - a.pot);
      const topFourElements = sortedArray.slice(0, 4);
      setGamesData(topFourElements);
    }
  };
  const handleGameForPublic = async () => {
    const response = await getPublicGamesForPublic();
    if (response?.data?.games?.length > 0) {
      const sortedArray = response?.data?.games?.sort((a, b) => b.pot - a.pot);
      const topFourElements = sortedArray.slice(0, 4);
      setGamesData(topFourElements);
    }
  };
  const handleViewRuleButton = () => {
    setNavItem(4);
    navigate("/rules");
  };
  const handleJoinGameButton = () => {
    setNavItem(1);
    navigate("/game");
  };
  const handleCreatePrivateGame = () => {
    setNavItem(null);
    navigate("/privategame");
  };
  useEffect(() => {
    if (userToken) {
      handleGameForUser();
    } else {
      handleGameForPublic();
    }
    setNavItem(0);
    if (
      (contextData?.signupData && contextData.signupData.isExcluded) ||
      (contextData?.userData && contextData?.isExcluded)
    ) {
      // if (contextData?.signupData) {
      openExcludedModal();
    } else if (
      contextData?.signupData &&
      !contextData.signupData.verification &&
      contextData.signupData.status === "refer"
    ) {
      openVerificationModal();
    } else if (
      contextData?.signupData &&
      contextData.signupData.status === "pass"
    ) {
      openDepositModal();
    }
  }, [contextData]);

  useEffect(() => {
    if (userToken) {
      var data = new FormData();
      data.append("event_name", "Home Page");
      sendMetaEvents(data);
    }
  }, []);

  useEffect(() => {
    getLocation();
  }, []);

  //removing country restriction for development
  useEffect(() => {
    if (userCountry && userCountry !== "United Kingdom") {
      openRestrictedModal();
    }
  }, [userCountry]);

  //handle google playstore redirect

  const handleImageClick = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.championschoice",
      "_blank"
    ); // Replace with your app's URL
  };

  const getLocation = () => {
    axios
      .get("https://pro.ip-api.com/json/?fields=61439&key=NkVd8EZml7wU2wt")
      .then((response) => {
        let data = response;
        setUserCountry(data.data.country);
      })
      .catch((error) => {
        console.log("error-----", error);
      });
  };
  return (
    <div>
      {userToken && (
        <>
          <DepositLimitModal
            visible={isDepositModalOpen}
            closeModal={closeDepositModal}
          />
          <VerificationWarning
            visible={isVerificationWarningOpen}
            closeModal={closeVerificationModal}
          />
          <ExcludedWarningModal
            visible={isExcluded}
            closeModal={closeExcludedModal}
          />
        </>
      )}
      <div className="main-home">
        <RestrictedAccess
          visible={accessRestricted}
          closeModal={closeRestrictedModal}
        />
        <div className="main-home-top-cont">
          <div className="black-background"></div>
          <BannerPromo handleJoinGameButton={handleJoinGameButton}/>
          <div className="main-home-top">
            <div>
              <div className="mobile-hide">
                <div className="intro-text font-cairo-extrabold line-height-44 custom-end">
                  <h1>GET IN THERE</h1>
                </div>
                <div className="main-home-cont text-start icons-mobile-view">
                  <div className="main-home-text">
                    Grab your mates and make some noise - pool betting just got
                    real.
                  </div>
                  <div className="home-store-icons mobileonly">
                    <div
                      className="cursor-pointer"
                      href="https://play.google.com/store/apps/details?id=com.championschoice"
                    >
                      <img
                        src={PlayStoreIcon}
                        alt="Play Store Icon"
                        onClick={handleImageClick}
                        role="button" // Improve accessibility
                        aria-label="Get it on Google Play"
                        className="home-store-icons-icon"
                      />
                    </div>
                    <a
                      href="https://apps.apple.com/gb/app/champions-choice/id6453472971"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="cursor-pointer">
                        <img
                          src={AppleStoreIcon}
                          alt="Apple Store Icon"
                          className="home-store-icons-icon"
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="mobile-view">
                <div className="home-store-icons mobileonly">
                  <div
                    className="cursor-pointer"
                    href="https://play.google.com/store/apps/details?id=com.championschoice"
                  >
                    <img
                      src={PlayStoreIcon}
                      alt="Play Store Icon"
                      onClick={handleImageClick}
                      role="button" // Improve accessibility
                      aria-label="Get it on Google Play"
                      className="home-store-icons-icon"
                    />
                  </div>
                  <a
                    href="https://apps.apple.com/gb/app/champions-choice/id6453472971"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="cursor-pointer">
                      <img
                        src={AppleStoreIcon}
                        alt="Apple Store Icon"
                        className="home-store-icons-icon"
                      />
                    </div>
                  </a>
                </div>

                <div className="intro-text font-cairo-extrabold line-height-44 custom-end">
                  <h1>GET IN THERE</h1>
                </div>
              </div>

              <div className="home-buttons home-desktoponly">
                <FilledButton
                  name={"Join a Game"}
                  btnClass="primary-background home-buttons-button "
                  type={screenWidth < 767 ? 1 : 2}
                  functionCall={handleJoinGameButton}
                />
                <SimpleFilledButton
                  name={"Create a Private Game"}
                  btnClass="lightgrey-background home-buttons-btncreate"
                  functionCall={handleCreatePrivateGame}
                />
              </div>
              <div className="home-store-icons home-desktoponly">
                <div className="cursor-pointer">
                  <img
                    src={PlayStoreIcon}
                    alt="Play Store Icon"
                    onClick={handleImageClick}
                    role="button" // Improve accessibility
                    aria-label="Get it on Google Play"
                    className="home-store-icons-icon"
                  />
                </div>
                <a
                  href="https://apps.apple.com/gb/app/champions-choice/id6453472971"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="cursor-pointer">
                    <img
                      src={AppleStoreIcon}
                      alt="Apple Store Icon"
                      className="home-store-icons-icon"
                    />
                  </div>
                </a>
              </div>
            </div>
            <VideoPlayer src={IntroVideo} />
            <div className="home-buttons mobileonly">
              <FilledButton
                name={"Join a Game"}
                btnClass="primary-background home-buttons-button "
                type={screenWidth < 767 ? 1 : 2}
                functionCall={handleJoinGameButton}
              />
              <SimpleFilledButton
                name={"Create a Private Game"}
                btnClass="lightgrey-background home-buttons-btncreate"
                functionCall={handleCreatePrivateGame}
              />
            </div>
          </div>
        </div>
        <div className="main-home-bottom-cont">
          <div className="master-game primary-color font-cairo-medium primary-color">
            MASTER THE GAME
          </div>
          <div className="how-play font-cairo-extrabold">How To Play</div>
          <Suspense fallback={<Loading />}>
            {screenWidth > 767 ? (
              <div className="home-steps extra-margins">
                <GameStep
                  step={"01"}
                  description={
                    "Join a game and start competing or create a private game with your friends"
                  }
                  image={HomeStep1}
                />
                <GameStep
                  step={"02"}
                  description={
                    "Choose your team of the week! Careful: you won’t be able to select that team again"
                  }
                  image={HomeStep2}
                />
                <GameStep
                  step={"03"}
                  description={
                    "Master your strategy every week to take home the big pot!"
                  }
                  image={HomeStep3}
                />
              </div>
            ) : (
              <Carousel infinite={false}>
                <GameStep
                  step={"01"}
                  description={
                    "Join a game and start competing or create a private game with your friends"
                  }
                  image={HomeStep1}
                />
                <GameStep
                  step={"02"}
                  description={
                    "Choose your team of the week! Careful: you won’t be able to select that team again"
                  }
                  image={HomeStep2}
                />
                <GameStep
                  step={"03"}
                  description={
                    "Master your strategy every week to take home the big pot!"
                  }
                  image={HomeStep3}
                />
              </Carousel>
            )}
          </Suspense>
          <div className="home-button-view-rules extra-margins">
            <FilledButton
              name={"View All Rules"}
              btnClass="primary-background home-buttons-read-all"
              type={1}
              functionCall={handleViewRuleButton}
            />
          </div>
          {gamesData?.length > 0 && (
            <>
              <div className="main-padding extra-margins">
                <div className="join-action font-cairo-medium primary-color">
                  JOIN THE ACTION NOW!
                </div>
                <div className="intro-text2 font-cairo-extrabold">
                  A glimpse into our most popular games!
                </div>
              </div>
              <div className="home-join-game-cont extra-margins">
                {gamesData
                  ? gamesData.map((item, index) => {
                      return <JoinGame gameDetails={item} key={index} />;
                    })
                  : null}
              </div>
            </>
          )}
          <div className="home-view-button extra-margins">
            <FilledButton
              name={"View All Games"}
              link={"/game"}
              btnClass="primary-background home-buttons-read-all"
              type={1}
            />
          </div>
          <div className="news-padding">
            <HomeNews page={"home"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;