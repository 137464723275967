import React, { useState, useEffect } from "react";
import GameTable from "./GameTable";
import { useNavigate } from "react-router-dom";
import { FilledButton } from "../../commonComponents";
import { setNavItem } from "../../../utils/localStorage";
import DepositSuccess from "../../modals/DepositSuccess/DepositSuccess";
import { useMyContext } from "../../../helpers/Context/MyContext";
import { getToken, getExcluded } from "../../../utils/localStorage";
import notify from "../../../utils/notificationService"
import "./Game.scss";

const Game = () => {
  const [isDepositSuccessOpen, setIsDepositSuccessOpen] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState();
  const { contextData, setContextData } = useMyContext();
  const userToken = getToken();
  const isExcluded = getExcluded();
  const userData = contextData?.userData;
  
  const navigate = useNavigate();

  const openWithdrawSuccessModal = () => {
    setIsDepositSuccessOpen(true);
  };
  const closeWithdrawSuccessModal = () => {
    setIsDepositSuccessOpen(false);
    navigate("/game");
  };
  useEffect(() => {
    setNavItem(1);
  }, []);

  const CheckUserExcluded = () => {
    if (userToken) {
      if (userData && (userData?.is_excluded || isExcluded)) {
        notify("Action restricted due to self-exclusion.","error")
      } else {
        navigate("/privategame");
      }
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    const paymentDone = params.get("payment_amount");
    setPaymentAmount(params.get("payment_amount"));
    if (paymentDone) {
      openWithdrawSuccessModal();
    }
  }, []);

  return (
    <div className="game white-color">
      <DepositSuccess
        visible={isDepositSuccessOpen}
        closeModal={closeWithdrawSuccessModal}
        amount={paymentAmount}
      />
      <div className="text-center">
        <div className="main-padding">
          <div className="game-join-action font-cairo-medium primary-color">
            JOIN THE ACTION NOW!
          </div>
          <div className="game-heading font-cairo-extrabold">
            Unleash Your Strategy & Become The Ultimate Champion!
          </div>
        </div>
        <div>
          <GameTable/>
        </div>
        <div className="game-button">
          <FilledButton
            name={"Create a Private Game"}
            link={"/privategame"}
            btnClass="primary-background"
            type={1}
            functionCall={CheckUserExcluded}
          />
        </div>
      </div>
    </div>
  );
};

export default Game;
