import React, { useState, useEffect } from "react";
import { Select, Radio, Spin } from "antd";
import PremierLeagueIcon from "../../../../../assets/images/premier_league.svg";
import {
  BorderedButton,
  SimpleFilledButton,
} from "../../../../commonComponents";
import {
  getFixturesData,
  editGame,
  getRemainingTeams,
} from "../../../../../utils/service";
import { getApiWithAuth } from "../../../../../utils/api";
import { GET_AMOUNT_TO_BE_PAID } from "../../../../../utils/apiEndPoints";
import DiscountButton from "../../../../commonComponents/DiscountButton/DiscountButton";
import { useMyContext } from "../../../../../helpers/Context/MyContext";
import { getToken, getExcluded } from "../../../../../utils/localStorage";
import notify from "../../../../../utils/notificationService";
import "./ExpandedDetails.scss";

const ExpandedDetails = ({ expandedRowsKey, buttonType, getOngoingGames }) => {
  const [teamOne, setTeamOne] = useState("Entry 1 - Select Team");
  const [teamTwo, setTeamTwo] = useState("Entry 2 - Select Team");
  const [teamThree, setTeamThree] = useState("Entry 3 - Select Team");
  const [entryOne, setEntryOne] = useState();
  const [entryTwo, setEntryTwo] = useState();
  const [entryThree, setEntryThree] = useState();
  const [fixturesResponse, setFixturesResponse] = useState([]);
  const { contextData, setContextData } = useMyContext();
  const [fixtureWeek, setFixtureWeek] = useState();
  const [loading, setLoading] = useState(false);
  const [entriesValue, setEntriesValue] = useState();
  const [turboFormattedDate, setTurboFormattedDate] = useState();
  const [newEntriesCount, setNewEntriesCount] = useState();
  const [teamsLength, setTeamsLength] = useState(
    expandedRowsKey?.teams ? Object?.keys(expandedRowsKey?.teams)?.length : null
  );

  const userToken = getToken();
  const userData = contextData?.userData;
  const excluded = getExcluded();
  const [amountToPay, setAmountToPay] = useState(0);

  const getAmount = async () => {
    try {
      setLoading(true); // Set loading to true before fetching data
      if (expandedRowsKey.teams.length === entriesValue) {
        setAmountToPay(null);
        setLoading(false); // Reset loading state
        return;
      }

      const response = await getApiWithAuth(
        GET_AMOUNT_TO_BE_PAID(expandedRowsKey?.id, 3 - newEntriesCount)
      );

      const validEntries =
        (entriesValue === 3 &&
          teamOne !== "Entry 1 - Select Team" &&
          teamTwo !== "Entry 2 - Select Team" &&
          teamThree !== "Entry 3 - Select Team") ||
        (entriesValue === 2 &&
          teamOne !== "Entry 1 - Select Team" &&
          teamTwo !== "Entry 2 - Select Team") ||
        (entriesValue === 1 && teamOne !== "Entry 1 - Select Team");

      if (validEntries) {
        setAmountToPay(response.amount);
      } else {
        setAmountToPay(null);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  useEffect(() => {
    getAmount();
  }, [entriesValue, teamOne, teamTwo, teamThree]);

  const handleTeamOne = (value) => {
    setTeamOne(value);
  };
  const handleTeamTwo = (value) => {
    setTeamTwo(value);
  };
  const handleTeamThree = (value) => {
    setTeamThree(value);
  };
  const handleStandingData = async () => {
    const res = await getRemainingTeams(expandedRowsKey?.id);
    const newArray1 = res?.data?.entry_1?.map((item) => ({
      key: item?.team_id,
      label: (
        <div
          className="expanded-details-dropdown-main font-cairo-normal"
          style={{ opacity: item?.selected && "0.5" }}
        >
          <div className="iconLeague">
            <img
              src={item?.team_picture}
              alt="Team Icon"
              className="image-cover-full"
            />
          </div>
          {item?.team_name}
        </div>
      ),
      value: item?.team_id,
      logo: item?.team_picture,
      disabled: item?.selected,
    }));
    const newArray2 = res?.data?.entry_2?.map((item) => ({
      key: item?.team_id,
      label: (
        <div
          className="expanded-details-dropdown-main font-cairo-normal"
          style={{ opacity: item?.selected && "0.5" }}
        >
          <div className="iconLeague">
            <img
              src={item?.team_picture}
              alt="Team Icon"
              className="image-cover-full"
            />
          </div>
          {item?.team_name}
        </div>
      ),
      value: item?.team_id,
      logo: item?.team_picture,
      disabled: item?.selected,
    }));
    const newArray3 = res?.data?.entry_3?.map((item) => ({
      key: item?.team_id,
      label: (
        <div
          className="expanded-details-dropdown-main font-cairo-normal"
          style={{ opacity: item?.selected && "0.5" }}
        >
          <div className="iconLeague">
            <img
              src={item?.team_picture}
              alt="Team Icon"
              className="image-cover-full"
            />
          </div>
          {item?.team_name}
        </div>
      ),
      value: item?.team_id,
      logo: item?.team_picture,
      disabled: item?.selected,
    }));
    setEntryOne(newArray1);
    setEntryTwo(newArray2);
    setEntryThree(newArray3);
  };
  const handleFixturesData = async () => {
    const res = await getFixturesData(expandedRowsKey?.season_id);
    setFixturesResponse(res?.data?.data);
    const week = res?.data?.data?.findIndex(
      (item) => item?.round == expandedRowsKey?.in_game_round_number
    );
    setFixtureWeek(week);
  };
  const handleEditGame = async () => {
    if (userToken) {
      if ((userData?.is_excluded || excluded) && newEntriesCount > teamsLength) {
        notify("Action restricted due to self-exclusion.", "error");
        return;
      }
    }
    setLoading(true);
    const combinedArray = [teamOne, teamTwo, teamThree].filter(
      (state) =>
        state !== undefined &&
        state !== null &&
        state !== "Entry 1 - Select Team" &&
        state !== "Entry 2 - Select Team" &&
        state !== "Entry 3 - Select Team"
    );
    let dataObject = {
      teams: combinedArray,
      round: expandedRowsKey?.round_number,
    };
    const response = await editGame(expandedRowsKey?.id, dataObject);
    if (response) {
      if (response.success) {
        getOngoingGames();
        setLoading(false);
        window.location.reload(false);
      } else {
        notify(response?.message, "error");
      }
    } else {
      notify("Something went wrong", "error");
      setLoading(false);

    }
  };

  const onChange = (e) => {
    setEntriesValue(e.target.value);
  };
  const handleTurboFormattedDate = () => {
    let newDate = new Date(expandedRowsKey.new_date);
    let weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let weekdayIndex = newDate.getDay();
    let weekdayName = weekdays[weekdayIndex];
    let monthName = newDate.toLocaleString("default", {
      month: "long",
    });
    let day = newDate.getDate();
    let year = newDate.getFullYear();
    let formattedDate = weekdayName + " " + monthName + " " + day + " " + year;

    setTurboFormattedDate(formattedDate);
  };

  useEffect(() => {
    handleStandingData();
    handleFixturesData();
    if (expandedRowsKey?.teams) {
      setTeamOne(
        expandedRowsKey?.teams.entry_1?.team_id
          ? expandedRowsKey?.teams.entry_1?.team_id
          : expandedRowsKey.is_turbo || expandedRowsKey.sport_id == 5
          ? "Entry - Select Team"
          : "Entry 1 - Select Team"
      );
      setTeamTwo(
        expandedRowsKey?.teams.entry_2?.team_id
          ? expandedRowsKey?.teams.entry_2?.team_id
          : "Entry 2 - Select Team"
      );
      setTeamThree(
        expandedRowsKey?.teams.entry_3?.team_id
          ? expandedRowsKey?.teams.entry_3?.team_id
          : "Entry 3 - Select Team"
      );
    }
    setEntriesValue(
      expandedRowsKey?.teams
        ? Object?.keys(expandedRowsKey?.teams)?.length
        : null
    );
    setNewEntriesCount(
      expandedRowsKey?.teams
        ? Object?.keys(expandedRowsKey?.teams)?.length
        : null
    );
    if (expandedRowsKey?.sport_id == 5 || expandedRowsKey.is_turbo == true) {
      handleTurboFormattedDate();
    }
  }, [expandedRowsKey]);

  return (
    <div className="expanded-details lightblack-background">
      <div className="expanded-details-top">
        {expandedRowsKey?.is_turbo && (
          <div className="expanded-details-turbotag font-cairo-bold">Turbo</div>
        )}
        <div className="expanded-details-top-left">
          <img
            src={
              expandedRowsKey.season_id == 23614
                ? PremierLeagueIcon
                : expandedRowsKey.league_image
            }
            alt="Profile Pic"
            className="expanded-details-top-left-image"
            style={{ width: expandedRowsKey?.sport_id == 5 ? "25px" : "47px" }}
          />
          <div className="expanded-details-top-left-text font-cairo-normal">
            <span className="font18">{expandedRowsKey?.league_name}</span>
            <span className="font14 grey-color">
              Round {expandedRowsKey?.round_number}
            </span>
          </div>
        </div>
        {expandedRowsKey?.sport_id == 3 &&
          expandedRowsKey.is_turbo == false &&
          buttonType === "addTeam" &&
          expandedRowsKey?.round_number == 1 && (
            <div className="expanded-details-top-right">
              <div>Number of entries to add</div>
              <Radio.Group
                onChange={onChange}
                value={entriesValue}
                className="expanded-details-top-right-radio"
              >
                <Radio
                  value={1}
                  disabled={
                    (teamsLength == 1 ||
                      teamsLength == 2 ||
                      teamsLength == 3) &&
                    true
                  }
                >
                  1
                </Radio>
                <Radio
                  value={2}
                  disabled={teamsLength == 2 || (teamsLength == 3 && true)}
                >
                  2
                </Radio>
                <Radio value={3} disabled={teamsLength == 3 && true}>
                  3
                </Radio>
              </Radio.Group>
            </div>
          )}
      </div>
      <div className="expanded-details-bottom">
        <div className="expanded-details-bottom-fixtures">
          <div className="font18 font-cairo-medium expanded-details-bottom-fixtures-title">
            Fixtures
          </div>
          {fixturesResponse?.length > 0 ? (
            expandedRowsKey?.sport_id == 5 ||
            expandedRowsKey.is_turbo == true ? (
              <>
                <div key={turboFormattedDate}>
                  <div className="expand-details-cont-date font-cairo-medium">
                    {turboFormattedDate}
                  </div>
                  {fixturesResponse[fixtureWeek]?.fixtures[
                    expandedRowsKey?.new_date
                  ]?.map((fixture, index) => {
                    const {
                      team1_name,
                      team2_name,
                      time,
                      team1_img,
                      team2_img,
                      is_postponed,
                    } = fixture;
                    return (
                      <div
                        className="expand-details-cont-teams font-cairo-normal"
                        key={index}
                      >
                        <div className="expand-details-cont-teams-one">
                          <div className="expand-details-cont-teams-one-name">
                            {team1_name}
                          </div>
                          <div className="expand-details-cont-teams-one-image">
                            <img
                              src={team1_img}
                              alt="Team Icon"
                              className="image-cover-full"
                            />
                          </div>
                        </div>
                        <div className="expand-details-cont-teams-time">
                          {is_postponed
                            ? "Postp."
                            : time
                                .split(" ")[1]
                                .split(":")
                                .slice(0, 2)
                                .join(":")}
                        </div>
                        <div className="expand-details-cont-teams-two">
                          <div className="expand-details-cont-teams-two-image">
                            <img
                              src={team2_img}
                              alt="Team Icon"
                              className="image-cover-full"
                            />
                          </div>
                          <div className="expand-details-cont-teams-two-name">
                            {team2_name}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="expand-details-cont-line"></div>
                </div>
              </>
            ) : (
              Object?.keys(fixturesResponse[fixtureWeek]?.fixtures).map(
                (date) => {
                  const fixturesArray =
                    fixturesResponse[fixtureWeek]?.fixtures[date];
                  let newDate = new Date(date);
                  let weekdays = [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                  ];
                  let weekdayIndex = newDate.getDay();
                  let weekdayName = weekdays[weekdayIndex];
                  let monthName = newDate.toLocaleString("default", {
                    month: "long",
                  });
                  let day = newDate.getDate();
                  let year = newDate.getFullYear();
                  let formattedDate =
                    weekdayName + " " + monthName + " " + day + " " + year;
                  return (
                    <div key={date}>
                      <div className="expand-details-cont-date font-cairo-medium">
                        {formattedDate}
                      </div>
                      {fixturesArray?.map((fixture, index) => {
                        const {
                          team1_name,
                          team2_name,
                          time,
                          team1_img,
                          team2_img,
                        } = fixture;
                        return (
                          <div
                            className="expand-details-cont-teams font-cairo-normal"
                            key={index}
                          >
                            <div className="expand-details-cont-teams-one">
                              <div className="expand-details-cont-teams-one-name">
                                {team1_name}
                              </div>
                              <div className="expand-details-cont-teams-one-image">
                                <img
                                  src={team1_img}
                                  alt="Team Icon"
                                  className="image-cover-full"
                                />
                              </div>
                            </div>
                            <div className="expand-details-cont-teams-time">
                              {time
                                .split(" ")[1]
                                .split(":")
                                .slice(0, 2)
                                .join(":")}
                            </div>
                            <div className="expand-details-cont-teams-two">
                              <div className="expand-details-cont-teams-two-image">
                                <img
                                  src={team2_img}
                                  alt="Team Icon"
                                  className="image-cover-full"
                                />
                              </div>
                              <div className="expand-details-cont-teams-two-name">
                                {team2_name}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="expand-details-cont-line"></div>
                    </div>
                  );
                }
              )
            )
          ) : (
            <div className="spinner-loading">
              <Spin className="loader" />
            </div>
          )}
        </div>
        <div className="expanded-details-bottom-selections">
          <div className="expanded-details-bottom-selections-title font18 font-cairo-medium">
            My Selections
          </div>
          <div className="selections-teams-list">
            {expandedRowsKey?.teams &&
            !expandedRowsKey?.in_progress &&
            Object?.keys(expandedRowsKey?.teams)?.length < 3 &&
            expandedRowsKey.round_number == 1 ? (
              <>
                {(entriesValue == 1 ||
                  entriesValue == 2 ||
                  entriesValue == 3) && (
                  <Select
                    defaultValue="Select Team"
                    value={teamOne}
                    onChange={handleTeamOne}
                    options={entryOne}
                    className="expanded-details-dropdown"
                    popupClassName="dropdown-popup-style expanded-details-dropdown-popup"
                  />
                )}
                {(entriesValue == 2 || entriesValue == 3) && (
                  <Select
                    defaultValue="Select Team"
                    onChange={handleTeamTwo}
                    value={teamTwo}
                    options={entryTwo}
                    className="expanded-details-dropdown"
                    popupClassName="dropdown-popup-style expanded-details-dropdown-popup"
                  />
                )}
                {entriesValue == 3 && (
                  <Select
                    defaultValue="Select Team"
                    onChange={handleTeamThree}
                    value={teamThree}
                    options={entryThree}
                    className="expanded-details-dropdown"
                    popupClassName="dropdown-popup-style expanded-details-dropdown-popup"
                  />
                )}
              </>
            ) : (
              <>
                {expandedRowsKey?.teams?.entry_1 && (
                  <Select
                    defaultValue="Select Team"
                    value={teamOne}
                    onChange={handleTeamOne}
                    options={entryOne}
                    className="expanded-details-dropdown"
                    popupClassName="dropdown-popup-style expanded-details-dropdown-popup"
                  />
                )}
                {expandedRowsKey?.teams?.entry_2 && (
                  <Select
                    defaultValue="Select Team"
                    onChange={handleTeamTwo}
                    value={teamTwo}
                    options={entryTwo}
                    className="expanded-details-dropdown"
                    popupClassName="dropdown-popup-style expanded-details-dropdown-popup"
                  />
                )}
                {expandedRowsKey?.teams?.entry_3 && (
                  <Select
                    defaultValue="Select Team"
                    onChange={handleTeamThree}
                    value={teamThree}
                    options={entryThree}
                    className="expanded-details-dropdown"
                    popupClassName="dropdown-popup-style expanded-details-dropdown-popup"
                  />
                )}
              </>
            )}
          </div>
          <div className="text-center padding-top2">
            {amountToPay !== 3 - newEntriesCount * expandedRowsKey?.stake ? (
              <SimpleFilledButton
               id="submit-modified-game-button"
                name={
                  loading ? (
                    <div className="auth-loading">
                      <Spin className="loader" />
                    </div>
                  ) : expandedRowsKey?.round_number == 1 &&
                    ((entriesValue === 3 &&
                      teamOne !== "Entry 1 - Select Team" &&
                      teamTwo !== "Entry 2 - Select Team" &&
                      teamThree !== "Entry 3 - Select Team") ||
                      (entriesValue === 2 &&
                        teamOne !== "Entry 1 - Select Team" &&
                        teamTwo !== "Entry 2 - Select Team") ||
                      (entriesValue === 1 &&
                        teamOne !== "Entry 1 - Select Team")) ? (
                    expandedRowsKey.stake == "0.0" ||
                    (entriesValue - newEntriesCount) * expandedRowsKey?.stake ==
                      0 ? (
                      "Submit"
                    ) : (
                      `Submit & Pay £${
                        (entriesValue - newEntriesCount) *
                        expandedRowsKey?.stake
                      }`
                    )
                  ) : (
                    "Submit"
                  )
                }
                btnClass={"expanded-details-bottom-selections-button"}
                functionCall={handleEditGame}
              />
            ) : (
              <DiscountButton
                name={
                  loading ? (
                    <div className="auth-loading">
                      <Spin className="loader" />
                    </div>
                  ) : (entriesValue === 3 &&
                      teamOne !== "Entry 1 - Select Team" &&
                      teamTwo !== "Entry 2 - Select Team" &&
                      teamThree !== "Entry 3 - Select Team") ||
                    (entriesValue === 2 &&
                      teamOne !== "Entry 1 - Select Team" &&
                      teamTwo !== "Entry 2 - Select Team") ||
                    (entriesValue === 1 &&
                      teamOne !== "Entry 1 - Select Team" &&
                      3 - newEntriesCount !== 0) ? (
                    expandedRowsKey.stake == "0.0" ? (
                      "Submit"
                    ) : (
                      `Submit & Pay`
                    )
                  ) : (
                    "Submit"
                  )
                }
                link={"#"}
                btnClass={"expand-details-bottom-selections-button"}
                functionCall={handleEditGame}
                actualAmount={amountToPay}
                strikeAmount={3 - newEntriesCount * expandedRowsKey?.stake}
              />
            )}
          </div>
          {expandedRowsKey.round_number !== 1 ? (
            <div className="text-center padding-top2">
              <BorderedButton
                name={"View Previous Selections"}
                link={"/dashboard/allselections"}
                btnHeight={"32px"}
                btnClass={"expanded-details-bottom-btnselections"}
                dataToSend={{
                  id: expandedRowsKey.id,
                  title: expandedRowsKey.title,
                  previousSelection: true,
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ExpandedDetails;
