import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import History from "./Deposit History/History";
import GameHistory from "./Game History/GameHistory";
import { Field, Form, Formik, ErrorMessage } from "formik";
import WithdrawHistory from "./Witdraw History/WithdrawHistory";
import SearchIcon from "../../../../src/assets/images/search .svg";
import { BorderedButton, SimpleFilledButton } from "../../commonComponents";
import {
  getAllGameActivities,
  getGameActivitiesByDate,
  allTransactionData,
  allTransactionDataByDate,
} from "../../../utils/service";
import "./Activity.scss";

const validationSchema = Yup.object().shape({
  fromDateHistory: Yup.date().required("From date is required"),
  toDateHistory: Yup.date()
    .required("To date is required")
    .min(Yup.ref("fromDateHistory"), "To date must be greater than from date")
    .max(new Date(), "To date cannot be a future date"),
});

const depositHistory = [
  {
    dateInterval: "From 22/03/23 to 22/04/23",
    amountWithdrawn: "$1500",
    paymentMethod: {
      cardNumber: " .....3823",
    },
  },
  {
    dateInterval: "From 22/03/23 to 22/04/23",
    amountWithdrawn: "$1500",
    paymentMethod: {
      cardNumber: " .....3823",
    },
  },
  {
    dateInterval: "From 22/03/23 to 22/04/23",
    amountWithdrawn: "$1500",
    paymentMethod: {
      cardNumber: " .....3823",
    },
  },
];
const Activity = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [gameHistoryData, setGameHistoryData] = useState([]);
  const [allTransactionResponse, setAllTransactionResponse] = useState();
  const [allTransactionWithdraw, setAllTransactionWithdraw] = useState();
  const [gameHistoryFlag, setGameHistoryFlag] = useState(true);
  const [paymentFlag, setPaymentFlag] = useState(false);
  const [customRange, setCustomRange] = useState(false);
  const [buttonCheck, setButtonCheck] = useState("");
  const [customToDates, setCustomToDate] = useState();
  const [customFromDates, setCustomFromDate] = useState();

  const handleActivitiesResponse = async (values) => {
    setButtonCheck(values);
    if (gameHistoryFlag) {
      if (values === "days") {
        const response = await getAllGameActivities(`days_filter=7`);
        setGameHistoryData(response.game_activities);
      } else if (values === "hours") {
        const response = await getAllGameActivities(`hours_filter=24`);
        setGameHistoryData(response.game_activities);
      } else if (values) {
        const response = await getGameActivitiesByDate(
          `to_date=${values.toDateHistory}`,
          `from_date=${values.fromDateHistory}`
        );
        setGameHistoryData(response.game_activities);
      } else {
        const response = await getAllGameActivities(`days_filter=7`);
        setGameHistoryData(response.game_activities);
      }
    }
    if (paymentFlag) {
      if (values === "days") {
        const response = await allTransactionData(`deposit`, `days_filter=7`);
        const withdrawResponse = await allTransactionData(
          `withdraw`,
          `days_filter=7`
        );
        setAllTransactionResponse(response?.data);
        setAllTransactionWithdraw(withdrawResponse?.data);
      } else if (values === "hours") {
        const response = await allTransactionData(`deposit`, `hours_filter=24`);
        const withdrawResponse = await allTransactionData(
          `withdraw`,
          `hours_filter=24`
        );
        setAllTransactionResponse(response?.data);
        setAllTransactionWithdraw(withdrawResponse?.data);
      } else if (values) {
        setCustomToDate(values.toDateHistory);
        setCustomFromDate(values.fromDateHistory);
        const response = await allTransactionDataByDate(
          `deposit`,
          `to_date=${values.toDateHistory}`,
          `from_date=${values.fromDateHistory}`
        );
        const withdrawResponse = await allTransactionDataByDate(
          `withdraw`,
          `to_date=${values.toDateHistory}`,
          `from_date=${values.fromDateHistory}`
        );
        setAllTransactionResponse(response?.data);
        setAllTransactionWithdraw(withdrawResponse?.data);
      } else {
        const response = await allTransactionData(`deposit`, `days_filter=7`);
        const withdrawResponse = await allTransactionData(
          `withdraw`,
          `days_filter=7`
        );
        setAllTransactionResponse(response?.data);
        setAllTransactionWithdraw(withdrawResponse?.data);
      }
    }
  };
  const handleAllTransactionData = async () => {
    const response = await allTransactionData(`deposit`, `days_filter=7`);
    const withdrawResponse = await allTransactionData(
      `withdraw`,
      `days_filter=7`
    );
    setAllTransactionResponse(response?.data);
    setAllTransactionWithdraw(withdrawResponse?.data);
  };
  const handlePaymentFlag = () => {
    setPaymentFlag(true);
    setGameHistoryFlag(false);
  };
  const handleGameFlag = () => {
    setGameHistoryFlag(true);
    setPaymentFlag(false);
  };
  const handleCustomRange = () => {
    setCustomRange(!customRange);
    setButtonCheck("");
  };
  const handleDateFormat = (inputDate) => {
    const dateStr = inputDate;
    const [year, month, day] = dateStr.split("-");
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  useEffect(() => {
    handleAllTransactionData();
    const checkWindowSize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleActivitiesResponse("days");
    window.addEventListener("resize", checkWindowSize);
    return () => {
      window.removeEventListener("resize", checkWindowSize);
    };
  }, []);

  return (
    <>
      <div className="mainContainerActivity">
        <div className="mainHeadingActivity">Activity</div>
        <div className="activity-buttons">
          <SimpleFilledButton
            name={"Game History"}
            btnHeight={"45px"}
            btnClass={
              gameHistoryFlag
                ? "activity-btngamehistory"
                : "activity-btncurrentactive"
            }
            functionCall={handleGameFlag}
          />
          <BorderedButton
            name={"Payment"}
            btnHeight={"45px"}
            btnClass={
              paymentFlag
                ? "activity-btngamehistory"
                : "activity-btncurrentactive"
            }
            functionCall={() => {
              handlePaymentFlag();
            }}
          />
        </div>
        <div style={{ marginLeft: "4px" }}>
          <div className="activity-title font-cairo-extrabold">Period</div>
          <div className="activity-mobile-buttons">
            <BorderedButton
              name={"Last 24 hours"}
              btnHeight={"45px"}
              btnClass={
                buttonCheck == "hours"
                  ? "activity-btngamehistory"
                  : "activity-btncurrentactive"
              }
              functionCall={() => {
                handleActivitiesResponse("hours");
              }}
            />
            <BorderedButton
              name={"Last 7 days"}
              btnHeight={"45px"}
              btnClass={
                buttonCheck == "days"
                  ? "activity-btngamehistory"
                  : "activity-btncurrentactive"
              }
              functionCall={() => {
                handleActivitiesResponse("days");
              }}
            />
            <SimpleFilledButton
              name={"Custom Range"}
              btnHeight={"45px"}
              btnClass={
                customRange
                  ? "activity-btngamehistory"
                  : "activity-btncurrentactive"
              }
              functionCall={handleCustomRange}
            />
          </div>
          {customRange && (
            <Formik
              initialValues={{
                fromDateHistory: "",
                toDateHistory: "",
              }}
              onSubmit={(values) => {
                handleActivitiesResponse(values);
              }}
              validationSchema={validationSchema}
            >
              {({ getFieldProps, values }) => (
                <Form>
                  <div className="subMainActivity">
                    <div className="insights-standings-dropdownss">
                      <div className="startTime">
                        <div className="fieldsCont">
                          <h5 className="timeHeadingFrom">From</h5>
                          <div
                            style={{
                              border: "1px solid yellow",
                              transform: "skew(-20deg)",
                            }}
                          >
                            <Field
                              {...getFieldProps("fromDateHistory")}
                              type="date"
                              className="start-input-field"
                              placeholder="Start Date"
                            />
                          </div>
                        </div>
                        <ErrorMessage
                          name="fromDateHistory"
                          component="div"
                          className="error-message activity-error"
                        />
                      </div>
                      <div className="startTime">
                        <div className="fieldsCont">
                          <h5 className="timeHeadingFrom">To</h5>
                          <div
                            style={{
                              border: "1px solid yellow",
                              transform: "skew(-20deg)",
                            }}
                          >
                            <Field
                              {...getFieldProps("toDateHistory")}
                              type="date"
                              className="start-input-field"
                              placeholder="End Date"
                              format="YYYY/MM/DD"
                            />
                          </div>
                        </div>
                        <ErrorMessage
                          name="toDateHistory"
                          component="div"
                          className="error-message activity-error"
                        />
                      </div>
                      <div className="outerSearch">
                        <button className="searchContainer" type="submit">
                          <img
                            className="searchIcon"
                            src={SearchIcon}
                            alt="Seacrch Icon"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
        {!isMobile ? (
          <>
            {gameHistoryFlag && (
              <>
                <div className="tableMainContainer">
                  <div className="styleTable">
                    {gameHistoryData.length == 0 ? (
                      <div className="game-table-nodata font-cairo-medium extraBottomMargin">
                        No Game Found
                      </div>
                    ) : (
                      <div
                        style={{ width: "100%" }}
                        className="insights-standings background-blur"
                      >
                        <History gameHistoryData={gameHistoryData} />
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            {paymentFlag && (
              <>
                <div className="historyHeading">Withdrawal History</div>
                <div className="tableMainContainer">
                  <div className="styleTable">
                    <div
                      style={{ width: "100%" }}
                      className="insights-standings background-blur"
                    >
                      <GameHistory
                        allTransactionResponse={allTransactionWithdraw}
                      />
                    </div>
                  </div>
                </div>
                <div className="historyHeading">Deposit History</div>
                <div className="tableMainContainer">
                  <div className="styleTable">
                    <div
                      style={{ width: "100%" }}
                      className="insights-standings background-blur"
                    >
                      <WithdrawHistory
                        allTransactionResponse={allTransactionResponse}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="mainContainer">
              {gameHistoryFlag && (
                <>
                  {/* <div className="historyHeading">History of game</div> */}
                  {gameHistoryData.length == 0 && (
                    <div className="game-table-nodata font-cairo-medium extraBottomMargin">
                      No Game Found
                    </div>
                  )}
                  <div className="mainContainerLeague">
                    {gameHistoryData?.map((league, index) => (
                      <div key={index}>
                        <div className="leagueMobile">
                          {league?.competitions?.name}
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                          <div className="leagueTable" key={index}>
                            <div className="dateJoin">
                              <div className="dateMobileApp ">Game Title</div>
                              <div className="dateText">
                                {league.game_title}
                              </div>
                            </div>
                            <div className="dateJoin">
                              <div className="dateMobileApp ">Date Joined</div>
                              <div className="dateText">
                                {league.date_joined}
                              </div>
                            </div>
                            <div className="dateJoin transparent-background">
                              <div className="dateMobileApp ">Stake</div>
                              <div className="dateText">£{league.stake}</div>
                            </div>
                            <div className="dateJoin">
                              <div className="dateMobileApp ">
                                Round Reached
                              </div>
                              <div className="dateText">
                                {league.round_reached}
                              </div>
                            </div>
                            <div className="dateJoin transparent-background">
                              <div className="dateMobileApp ">Outcome</div>
                              <div className="dateText">
                                {league.status}
                                {league.status == "Won" &&
                                  ` £${Math.floor(league.pot)}`}
                              </div>
                            </div>
                            <div className="dateJoin transparent-background">
                              <div className="dateMobileApp grey-color"></div>
                              {league.status !== "In Progress" && (
                                <BorderedButton
                                  name={"View All Stats"}
                                  link={"/dashboard/allselections"}
                                  btnHeight={"45px"}
                                  btnClass={"dateJoin-button"}
                                  dataToSend={{
                                    id: league.id,
                                    title: league.game_title,
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {paymentFlag && (
                <>
                  <div className="historyHeading">Withdrawal History</div>
                  <div className="mainOuterWithdraw">
                    <div className="withdrawHisotyMain">
                      {customFromDates && customFromDates && (
                        <div className="rangedates">
                          From {customFromDates} to {customToDates}
                        </div>
                      )}
                      {allTransactionWithdraw?.map((withdrawal, index) => (
                        <div className="withdrawSubMain" key={index}>
                          <div className="dateJoin transparent-background">
                            <div className="dateMobileApp ">Date</div>
                            <div className="dateText">
                              {withdrawal.created_at}
                            </div>
                          </div>
                          <div className="dateJoin transparent-background">
                            <div className="dateMobileApp ">Amount</div>
                            <div className="dateText">£{withdrawal.amount}</div>
                          </div>
                          <div className="dateJoin">
                            <div className="dateMobileApp ">
                              Withdawal method
                            </div>
                            <div className="dateText">
                              <h5 className="cardDetail">
                                {withdrawal.payment_method}
                              </h5>
                            </div>
                          </div>
                          <div className="dateJoin">
                            <div className="dateMobileApp ">Transaction ID</div>
                            <div className="dateText">
                              <h5 className="cardDetail">
                                {withdrawal.payment_id}
                              </h5>
                            </div>
                          </div>
                          {index !== withdrawal.length - 1 && (
                            <hr className="activityHr" />
                          )}
                        </div>
                      ))}
                      {allTransactionWithdraw?.length > 0 ? null : (
                        <div className="withdrawHisotyMain-nodata font-cairo-bold">
                          No Withdrawals Found
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="historyHeading">Deposit History</div>
                  <div className="mainOuterWithdraw">
                    <div className="withdrawHisotyMain">
                      {customFromDates && customFromDates && (
                        <div className="rangedates">
                          From {customFromDates} to {customToDates}
                        </div>
                      )}
                      {allTransactionResponse?.map((deposit, index) => (
                        <div className="withdrawSubMain " key={index}>
                          <div className="dateJoin transparent-background">
                            <div className="dateMobileApp">Date</div>
                            <div className="dateText">{deposit.created_at}</div>
                          </div>
                          <div className="dateJoin transparent-background">
                            <div className="dateMobileApp">Amount</div>
                            <div className="dateText">£{deposit.amount}</div>
                          </div>
                          <div className="dateJoin">
                            <div className="dateMobileApp">Deposit method</div>
                            <div className="dateText">
                              <h5 className="cardDetail">
                                {deposit.payment_method}
                              </h5>
                            </div>
                          </div>
                          <div className="dateJoin">
                            <div className="dateMobileApp">Transaction ID</div>
                            <div className="dateText">
                              <h5 className="cardDetail">
                                {deposit.payment_id}
                              </h5>
                            </div>
                          </div>
                          {index !== depositHistory.length - 1 && (
                            <hr className="activityHr" />
                          )}
                        </div>
                      ))}
                      {allTransactionResponse?.length > 0 ? null : (
                        <div className="withdrawHisotyMain-nodata font-cairo-bold">
                          No Deposits Found
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Activity;
