import React from "react";
import { useCountdown } from "../../../helpers/timer";
import "./PromoCounter.scss";

const PromoCounter = (props) => {
  const [days, hours, minutes, seconds] = useCountdown(props.record);

  return (
    <div className="banner-subtext">
      <div>Starts in </div>&nbsp;
      <div className="countdown">
        <span className="time-unit">{days}</span> days &nbsp;
        <span className="time-unit">{hours}</span> hrs &nbsp;
        <span className="time-unit">{minutes}</span> min &nbsp;
        <span className="time-unit">{seconds}</span> sec
      </div>
    </div>
  );
};

export default PromoCounter;
