import React, { useEffect, useState } from "react";
import { Input, Table } from "antd";
import { useScreenWidth } from "../../../../utils/useScreenWidth";
import SearchIcon from "../../../../assets/images/search-icon.svg";
import WonTick from "../../../../assets/images/won-tick.png";
import LostTick from "../../../../assets/images/lost-tick.png";
import "./AllSelectionsTable.scss";

const AllSelectionsTable = ({ tableData, roundCount }) => {
  const screenWidth = useScreenWidth();
  const [searchText, setSearchText] = useState();
  const [filteredTableData, setFilteredTableData] = useState(tableData);
  const [columns, setColumns] = useState([]);

  const handleSearch = () => {
    const filteredData = tableData.filter((record) => {
      return record?.username
        ?.toLowerCase()
        .includes(searchText?.toLowerCase());
    });
    setFilteredTableData(filteredData);
  };

  const addColumn = () => {
    const dynamicColumns = [
      {
        title: (
          <div className="all-selection-table-username">
            <Input
              type="search"
              className="filterSelection"
              placeholder="Search by username"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <img className="filterAllSelection" src={SearchIcon} alt="filter" />
          </div>
        ),
        dataIndex: "username",
        width: screenWidth > 767 ? "220px" : "180px",
      },
    ];
    if (columns.length < roundCount) {
      for (let i = 1; i <= roundCount; i++) {
        dynamicColumns.push({
          title: `Round ${i}`,
          dataIndex: `team_${i}`,
          width: "120px",
          render: (_, record) => {
            return (
              <div className="all-selection-table-icons">
                {record[`team_${i}`]}
                {record[`team_${i}`] ? (
                  record[`team_${i}_is_winner`] ? (
                    <img
                      src={WonTick}
                      alt="Status-Icon"
                      className="result-icon"
                    />
                  ) : record[`team_${i}_is_winner`]==false &&(
                    <img
                      src={LostTick}
                      alt="Status-Icon"
                      className="result-icon"
                    />
                  )
                ) : (
                  <div>------</div>
                )}
              </div>
            );
          },
        });
        setColumns([...dynamicColumns]);
      }
    }
  };
  useEffect(() => {
    addColumn();
  }, [roundCount]);

  useEffect(() => {
    if (searchText) {
      handleSearch();
    } else {
      setFilteredTableData(tableData);
    }
  }, [searchText, tableData]);

  return (
    <div className="all-selection-table position-relative container-border background-blur">
      <Table
        columns={columns}
        dataSource={filteredTableData}
        className="all-selection-table-cont font-cairo-normal"
        rowClassName="all-selection-table-row"
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default AllSelectionsTable;
