import React from "react";
import { Table } from "antd";
import { BorderedButton } from "../../../commonComponents";
import "./History.scss";

const History = ({ gameHistoryData }) => {
  const handleDateFormat = (inputDate) => {
    const dateStr = inputDate;

    const date = new Date(dateStr);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();

    // Format to DD-MM-YYYY
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  const columns = [
    {
      title: "League",
      dataIndex: "league",
      key: "league",
      render: (_, record) => {
        return (
          <div className="tableHistory-competitions">
            {record.competitions.name}
          </div>
        );
      },
    },
    {
      title: "Game Title",
      dataIndex: "game_title",
      key: "sport_name",
    },
    {
      title: "Date Joined",
      dataIndex: "date_joined",
      render: (_, record) => {
        return (
          <div className="tableHistory-competitions">
            {handleDateFormat(record.date_joined)}
          </div>
        );
      },
    },
    {
      title: "Stake",
      dataIndex: "stake",
      render: (_, record) => {
        return <div>£{Math.floor(record.stake)}</div>;
      },
    },
    {
      title: "Round Reached",
      dataIndex: "round_reached",
    },
    {
      title: "Outcome",
      dataIndex: "status",
      key: "losses",
      render: (_, record) => {
        return (
          <div className="tableHistory-table-outcome">
            <div>
              {record.status}
              {record.status == "Won" && ` £${Math.floor(record.pot)}`}
            </div>
            {record.status !== "In Progress" && (
              <BorderedButton
                name={"View All Stats"}
                link={"/dashboard/allselections"}
                btnHeight={"45px"}
                btnClass={"past-table-actions-button"}
                dataToSend={{ id: record.id, title: record.game_title }}
              />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="tableHistory">
        <Table
          columns={columns}
          pagination={false}
          className="tableHistory-table activity-history-table"
          dataSource={gameHistoryData}
          scroll={{
            x: 767,
          }}
        />
      </div>
    </>
  );
};
export default History;
